import React from 'react';
import Helmet from 'react-helmet';

export default ({ title, metaDescription }) => {
	return (
		<Helmet>
			{title && <title>{title}</title>}
			<meta
				name='description'
				content='With a practice spanning across two decades, Marquise Stillwell’s journey began with a curiosity for people and spaces that later developed into a passion for designing systems that make all environments better for everyone. Holding an MBA and MA in Economics, Marquise was interested in social behavior and how humans negotiate space and value. Early on, he took on several corporate positions with an understanding that he would soon be executing his ideas independently. Working as an intrapreneur, he was able to leverage his talent to grasp the way these large ecosystems operate and how people work together.'
			/>

			<meta property='og:url' content={`https://quise.design`} />
			<meta property='og:title' content={title} />
			<meta property='og:site_name' content={title} />
			<meta
				property='og:description'
				content='With a practice spanning across two decades, Marquise Stillwell’s journey began with a curiosity for people and spaces that later developed into a passion for designing systems that make all environments better for everyone. Holding an MBA and MA in Economics, Marquise was interested in social behavior and how humans negotiate space and value. Early on, he took on several corporate positions with an understanding that he would soon be executing his ideas independently. Working as an intrapreneur, he was able to leverage his talent to grasp the way these large ecosystems operate and how people work together.'
			/>

			<meta property='og:image' content='/images/og-image.png?w=1200&h=630' />

			<meta name='twitter:card' content='summary_large_image' />
			<meta name='twitter:title' content={title} />
			<meta
				name='twitter:description'
				content='With a practice spanning across two decades, Marquise Stillwell’s journey began with a curiosity for people and spaces that later developed into a passion for designing systems that make all environments better for everyone. Holding an MBA and MA in Economics, Marquise was interested in social behavior and how humans negotiate space and value. Early on, he took on several corporate positions with an understanding that he would soon be executing his ideas independently. Working as an intrapreneur, he was able to leverage his talent to grasp the way these large ecosystems operate and how people work together.'
			/>
			<meta name='twitter:url' content={`https://quise.design`} />

			<meta name='twitter:image:src' content='/images/og-image.png?w=1200&h=630' />
		</Helmet>
	);
};
